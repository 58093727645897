import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { withPrefix } from 'gatsby'

const SecondPage = () => (
  <Layout>
    <SEO title="MHM 99" />
    <h1>MHM 99 </h1>

    <p>
      MHM 97:n orastavan menestyksen innoittamana aloitin kokonaista kaksi
      peliprojektia. MHM 98 tuli olemaan jääkiekkomanagerisimulaatio, jonka
      jälkeen ei enää tarvittaisi uusia jääkiekkomanagerisimulaatioita.
      Ultimaattinen Fantasia (joopa joo, olin vissiin pelannut liikaa Final
      Fantasy seiskaa) taasen sai luvan olla Nethackin tyyppinen merkkipohjainen
      ropellus, mutta editorilla jolla koko maailma tekisi siihen uusia
      seikkailuja. Ennen pitkää priorisoin jälkimmäisen edellisen
      kustannuksella.
    </p>

    <p>
      Taoin mahtiropeani lyhyesti, mutta intensiivisesti, kunnes ymmärsin urakan
      mahdottomaksi. Olin sentään oppinut joitain asioita, jotka kannustivat
      minut hylkäämään MHM 98:n koodipohjan ja lähtemään liikkeelle puhtaalta
      pöydältä. Otin samalla EA Sportsin kiinni vuosiluvussa -- oli{' '}
      <em>MHM 99:n</em>
      vuoro näyttää kyntensä.
    </p>

    <h2>Oikeat pelaajat tai kuolema</h2>

    <p>
      MHM 99:n kantava teema oli oikeat pelaajat -- tarkoittaen sitä, että
      pelaajan joukkueilla on pelaajia yksinkertaisen taitolukeman sijaan, ja
      managerin vastuulla on paitsi pelaajakaupat, myös ketjujen kasaaminen ja
      muuta aiheeseen liittyvää.
    </p>

    <p>
      Käyttöliittymällisesti edellämainitut ja muut eteenpäinviedyt asiat ovat
      luonnollisesti haastavampia kuin simppeli Enterin rämpyttäminen, ja
      kokemattomuuteni näkyy: MHM 99 on kaikista koskaan tekemistäni tuotteista,
      digitaalisista tai ei, kuvallisesti kuvottavin.
    </p>

    <p>
      Olen ensimmäisenä myöntämässä, ettei minulla ollut eikä ole mitään
      graafista tajua, mutta nykyään sentään ymmärrän matkia ja kopioida
      paremmin osaavia tai valmista enkä kylve omassa tyylitajuttomuudessani
      kuten MHM 99:n aikoihin. Käyttöliittymä, värit, kaikki ysiysiin liittyvä
      on susirumaa ja sekavaa. Ainoa, mitä en ymmärrä, on kuinka en kymmenen
      vuotta sitten osannut tai tahtonut reagoida asiaan. Haha!
    </p>

    <p>
      Keksin ysiysiä varten paljon kaikenlaista uutta ja ihmeellistä, mutta en
      joko osannut hyödyntää niitä konseptuaalisesti tai teknisesti parhaalla
      mahdollisella tavalla. Jälkikäteen ajateltuna MHM 99 on ensimmäisen
      trilogiani heikoin lenkki. Ei niin heikko kuin muistin, nyt vuonna 2019
      MHM 99 tuntui itse asiassa ihan OK:lta, mutta heikoin se silti on.
    </p>

    <p>
      Totta kai osansa asiassa on MHM 2000:lla, joka on ikään kuin MHM 99:n
      turboahdettu kasvojenkohotus ja tekee edeltäjänsä täysin turhaksi.
      "Ysiseiskalla" on minulle paljon tunnearvoa, mutta MHM 99:lle on hankalaa
      keksiä muuta oikeutusta kuin MHM 2000:n ideologisena pohjana toimiminen.
    </p>

    <h2>Pelaa</h2>

    <p>
      En aio remasteroida MHM 99:ää, en ainakaan ennen kuin kylven rahassa ja
      voin tehdä sen koska on sellainen olo. En usko että kukaan kaipaa tätä.
      Hyppään ysiseiskan remasteroituani suoraan kakstonniseen, jotta toiseen
      trilogiaani jää vielä varaus kolmannelle pelille.
    </p>

    <p>
      DOS-versiot kyllä toimivat jos kiinnostaa kokeilla. Katso alas, ystävä.
    </p>

    <h2>Lataa</h2>

    <p>
      MHM 99 on DOS-peli. Tarvitset{' '}
      <a target="_blank" href="https://www.dosbox.com/">
        Dosboxin
      </a>{' '}
      pelataksesi sitä.
    </p>

    <ul>
      <li>
        <a href={withPrefix('/files/mhm99fw.zip')}>MHM 99 Freeware</a>
      </li>
    </ul>

    <Link to="/">Takaisin</Link>
  </Layout>
)

export default SecondPage
